const moduleName = 'tooltip';

export function tooltip() {

    Array.from(document.querySelectorAll('.js-tooltip')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const SPEED = 300;

        const target = self;
        const hero = target.querySelector('.js-tooltip-hero');
        const tooltipText = target.title;
        let tooltip;
        let tooltipArrow;

        target.addEventListener('mouseenter', () => {
            if (document.body.classList.contains('mobile')) return;

            showTooltip();
        });

        target.addEventListener('mouseleave', () => {
            if (document.body.classList.contains('mobile')) return;

            hideTooltip();
        });

        target.addEventListener('click', () => {
            if (!document.body.classList.contains('mobile')) return;

            if (!tooltip) showTooltip();
        });

        document.addEventListener('touchstart', (event) => {
            if (event.target != target && tooltip) hideTooltip();
        });



        function showTooltip() {
            const heroPosition = hero.getBoundingClientRect();
            const heroOffset = {
                left: heroPosition.left + (window.scrollX || document.body.scrollLeft),
                top: heroPosition.top + (window.scrollY || document.body.scrollTop)
            };

            target.removeAttribute('title');
            tooltip = document.createElement('div');
            tooltipArrow = document.createElement('div');
            tooltip.classList.add('tooltip');
            if (target.dataset.textLeft) tooltip.classList.add('_left');
            tooltipArrow.classList.add('tooltip-arrow');
            tooltip.innerHTML = tooltipText;
            document.body.appendChild(tooltip);
            document.body.appendChild(tooltipArrow);

            tooltip.style.top = heroOffset.top + 'px';
            tooltipArrow.style.top = heroOffset.top + 'px';
            tooltipArrow.style.left = heroOffset.left + hero.offsetWidth / 2 + 'px';

            if (tooltip.offsetWidth / 2 + hero.offsetWidth / 2 + heroOffset.left > document.body.clientWidth) {
                tooltip.style.right = 0;
            } else if (heroOffset.left + hero.offsetWidth / 2 < tooltip.offsetWidth / 2) {
                tooltip.style.left = tooltip.offsetWidth / 2 + 'px';
            } else {
                tooltip.style.left = heroOffset.left + hero.offsetWidth / 2 + 'px';
            }

            setTimeout(function() {
                tooltip.classList.add('_visible');
                tooltipArrow.classList.add('_visible');
            }, 10);
        }

        function hideTooltip() {
            tooltip.classList.remove('_visible');
            tooltipArrow.classList.remove('_visible');

            setTimeout(() => {
                document.body.removeChild(tooltip);
                document.body.removeChild(tooltipArrow);
                target.setAttribute('title', tooltipText);
                tooltip = undefined;
                tooltipArrow = undefined;
            }, SPEED);
        }
    });

}