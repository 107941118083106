const moduleName = 'spinner';

export function spinner() {

    Array.from(document.querySelectorAll('.js-spinner')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const spinner = self;
        const entry = spinner.querySelector('.js-spinner-entry');
        const btnMinus = spinner.querySelector('.js-spinner-btn-minus');
        const btnPlus = spinner.querySelector('.js-spinner-btn-plus');

        const settings = {
            'min': entry.getAttribute('min') || 1,
            'max': entry.getAttribute('max') || 99999,
            'step': entry.getAttribute('step') || 1
        };

        spinner.addEventListener('change', changeValueEntry);
        changeButtonsActivity();

        if (btnMinus) {
            btnMinus.addEventListener('click', (event) => {
                event.preventDefault();

                if (btnMinus.classList.contains('_disabled')) return;

                changeValueEntry('minus');
            });
        }

        if (btnPlus) {
            btnPlus.addEventListener('click', (event) => {
                event.preventDefault();

                if (btnPlus.classList.contains('_disabled')) return;

                changeValueEntry('plus');
            });
        }



        function changeValueEntry(direction) {
            let entryValue = parseFloat(entry.value);

            entry.value = entryValue;
            if (direction === 'minus') entry.value = -settings.step + entryValue;
            if (direction === 'plus') entry.value = +settings.step + entryValue;
            if (entryValue < settings.min) entry.value = settings.min;
            if (entryValue > settings.max) entry.value = settings.max;

            if (isNaN(entryValue)) entry.value = settings.min;

            changeButtonsActivity();

            entry.dispatchEvent(new CustomEvent('changeSpinner', { bubbles: true }));
        }



        function changeButtonsActivity() {
            if (entry.value === settings.min) {
                btnMinus.classList.add('_disabled');
            } else {
                btnMinus.classList.remove('_disabled');
            }

            if (entry.value === settings.max) {
                btnPlus.classList.add('_disabled');
            } else {
                btnPlus.classList.remove('_disabled');
            }
        }
    });

}