import Swal from 'sweetalert2';

const moduleName = 'form';

export function form() {

    Array.from(document.querySelectorAll('.js-form')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const form = self;

        form.addEventListener('submit', (event) => {
            event.preventDefault();

            const xhr = new XMLHttpRequest();
            const formData = new FormData(form);
            const url = form.getAttribute('action');

            xhr.open('POST', url);
            xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                    popup().close();
                    Swal.fire(
                        'Отправлено!',
                        'Мы обязательно свяжемся с вами',
                        'success'
                    );
                }
            };
            xhr.send(formData);
        });

    });
}