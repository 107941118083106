import noUiSlider from 'nouislider';

const moduleName = 'order';

export function order() {

    Array.from(document.querySelectorAll('.js-order')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const holder = self;
        const select = holder.querySelector('.js-order-select');
        const areaEntry = holder.querySelector('.js-order-area-entry');
        const note = holder.querySelector('.js-order-note');
        const quantityEntry = holder.querySelector('.js-order-quantity-entry');
        const quantityOutput = holder.querySelector('.js-order-quantity-output');
        const images = holder.querySelectorAll('.js-order-images');
        const thicknessOutput = holder.querySelector('.js-order-thickness-output');
        const areaOutput = holder.querySelector('.js-order-area-output');
        const calculatedAreaOutputs = holder.querySelectorAll('.js-order-calculated-area-output');
        const priceOutputs = holder.querySelectorAll('.js-order-price-output');
        const meterPriceOutput = holder.querySelector('.js-order-meter-price-output');
        const sizeOutput = holder.querySelector('.js-order-size-output');
        const unitRadios = holder.querySelectorAll('.js-order-unit');
        const sizeHidden = holder.querySelector('.js-order-size-hidden');
        const quantityHidden = holder.querySelector('.js-order-quantity-hidden');
        const unitHidden = holder.querySelector('.js-order-unit-hidden');
        const areaHidden = holder.querySelector('.js-order-area-hidden');
        const priceHidden = holder.querySelector('.js-order-price-hidden');
        const range = holder.querySelector('.js-order-range');
        const rangeValues = range.querySelectorAll('.js-order-range-value');
        const rangeSliderElement = range.querySelector('.js-order-range-slider');
        const rangeSlider = noUiSlider.create(rangeSliderElement, {
            connect: [true, false],
            step: 1,
            start: select.value,
            range: {
                'min': 0,
                'max': (+rangeValues.length - 1)
            }
        });

        rangeValues[0].classList.add('_active');
        rangeSlider.on('update', () => {
            const rangeSliderValue = +rangeSlider.get();
            const activeRangeValue = range.querySelector('.js-order-range-value._active');

            activeRangeValue.classList.remove('_active');
            rangeValues[rangeSliderValue].classList.add('_active');
            select.value = rangeSliderValue;
            calcOrder();
        });

        Array.from(rangeValues).forEach((rangeValue, index) => {
            rangeValue.addEventListener('click', () => {
                if (rangeValue.classList.contains('_active')) return;

                rangeSlider.set([index]);
            });
        });

        select.addEventListener('change', () => {
            rangeSlider.set([select.value]);
        });

        areaEntry.addEventListener('input', () => {
            if (areaEntry.value > 0 && areaEntry.classList.contains('_error')) {
                areaEntry.classList.remove('_error');
            }

            calcOrder();
        });
        quantityEntry.addEventListener('changeSpinner', () => {
            areaEntry.value = '';
            calcOrder();
        });

        Array.from(unitRadios).forEach((unitRadio) => {
            unitRadio.addEventListener('change', () => {
                let activeIndex = select.value;
                let selectedOption = select.options[activeIndex];
                let amount = selectedOption.dataset.amount;

                if (unitRadio.value === 'piece') {
                    quantityEntry.value = quantityEntry.value * amount;
                } else if (unitRadio.value === 'pallet') {
                    quantityEntry.value = Math.ceil(quantityEntry.value / amount);
                }

                calcOrder();
            });
        });



        function calcOrder() {
            let activeIndex = select.value;
            let selectedOption = select.options[activeIndex];
            let checkedUnit = holder.querySelector('.js-order-unit:checked');
            let isPallet = checkedUnit.value === 'pallet' ? true : false;
            let enteredArea = +areaEntry.value;
            let area = +selectedOption.dataset.area;
            let price = +selectedOption.dataset.price;
            let amount = isPallet ? +selectedOption.dataset.amount : 1;
            let selectedOptionText = selectedOption.innerText;
            let selectedThickness = selectedOptionText.split(' ')[0];
            let calculatedPrice;

            if (enteredArea) {
                quantityEntry.value = Math.ceil(enteredArea / area / amount);
                note.classList.add('_visible');
            } else {
                note.classList.remove('_visible');
            }

            // output quantity
            quantityOutput.innerText = quantityEntry.value;

            // output type
            sizeOutput.innerText = selectedOptionText;

            // show active images
            Array.from(images).forEach((imagesHolder) => {
                const innerImages = imagesHolder.querySelectorAll('.js-order-image');
                const activeImage = imagesHolder.querySelector('.js-order-image._active');

                activeImage.classList.remove('_active');
                innerImages[activeIndex].classList.add('_active');
            });

            // output thickness
            thicknessOutput.innerText = selectedThickness;

            // output entered area
            areaOutput.innerText = enteredArea;

            // output calculated area
            Array.from(calculatedAreaOutputs).forEach((calculatedAreaOutput) => {
                calculatedAreaOutput.innerText = Math.ceil(quantityEntry.value * amount * area * 100) / 100;
            });

            // output calculated price
            if (isPallet && selectedOption.dataset.priceP.length > 0) {
                let palletPrice = +selectedOption.dataset.priceP;

                calculatedPrice = Math.ceil(quantityEntry.value * palletPrice * 100) / 100;
            } else {
                calculatedPrice = Math.ceil(quantityEntry.value * amount * price * 100) / 100;
            }

            Array.from(priceOutputs).forEach((priceOutput) => {
                priceOutput.innerText = calculatedPrice;
            });

            // output calculated price per meter
            meterPriceOutput.innerText = Math.ceil(price / area * 100) / 100;

            // fill hidden inputs
            sizeHidden.value = selectedOptionText;
            quantityHidden.value = quantityEntry.value;
            unitHidden.value = checkedUnit.value === 'piece' ? 'Штуки' : 'Поддоны';
            areaHidden.value = Math.ceil(quantityEntry.value * amount * area * 100) / 100;
            priceHidden.value = calculatedPrice;
        }
    });
}