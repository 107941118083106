import { scrollToY } from './scrollToY';

const moduleName = 'scrollTo';

export function scrollTo() {

    Array.from(document.querySelectorAll('.js-scroll-to')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        self.addEventListener('click', (event) => {
            const targetSelector = self.dataset.target || self.getAttribute('href') || undefined;
            const target = targetSelector ? document.querySelector(targetSelector) : document.documentElement;
            const y = target.getBoundingClientRect().top + window.scrollY;

            scrollToY(y);

            event.preventDefault();
        });
    });
}