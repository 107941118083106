const moduleName = 'contacts';

export function contacts() {

    Array.from(document.querySelectorAll('.js-contacts')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const holder = self;
        const url = holder.dataset.url;
        const mapHolder = holder.querySelector('.js-contacts-map');
        const regionSelect = holder.querySelector('.js-contacts-region');
        const typeSelect = holder.querySelector('.js-contacts-type');
        const googleMapsScript = document.createElement('script');
        const markerUrl = JSON.parse(mapHolder.dataset.markers);
        let markers;

        googleMapsScript.type = 'text/javascript';
        googleMapsScript.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyC03k-vwMpPcMHdbUgDRJJM-C5QWXUC-AI&callback=initContactsMap';
        document.body.appendChild(googleMapsScript);

        window.initContactsMap = () => {
            const mapOptions = {
                scrollwheel: false,
                navigationControl: false,
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false
            };
            const map = new google.maps.Map(mapHolder, mapOptions);
            let markersOnMap = [];
            let xhr = new XMLHttpRequest();
            xhr.onreadystatechange = () => {
                if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                    markers = JSON.parse(xhr.responseText);
                    setMarkers();
                    regionSelect.addEventListener('change', setMarkers);
                    typeSelect.addEventListener('change', setMarkers);
                }
            };
            xhr.open('GET', url, true);
            xhr.send();



            function setMarkers() {
                const bounds = new google.maps.LatLngBounds();

                markersOnMap.forEach((markerOnMap) => {
                    markerOnMap.setMap(null);
                });
                markersOnMap = [];

                markers.forEach((marker) => {
                    if (marker.type != typeSelect.value && marker.type != 'all') return;
                    if (marker.region != regionSelect.value && regionSelect.value != 'all') return;

                    let markerContent = `<b>${marker.name}</b><br><br><b>Адрес:</b> ${marker.adr}<br><b>Телефон:</b> ${marker.tel}`;
                    let markerSite;

                    if (marker.site !== undefined && marker.site !== '') {
                        markerSite = `<br><b>Сайт:</b> <a href="${marker.site}">${marker.site}</a>`;
                    } else {
                        markerSite = '';
                    }

                    markerContent += markerSite;

                    const markerImage = {
                        url: markerUrl[marker.iconType],
                        size: new google.maps.Size(40, 52),
                        scaledSize: new google.maps.Size(40, 52),
                        anchor: new google.maps.Point(20, 52)
                    };
                    const visibleMarkerLatLng = new google.maps.LatLng(marker.lat, marker.lng);
                    const visibleMarker = new google.maps.Marker({
                        position: visibleMarkerLatLng,
                        map: map,
                        icon: markerImage,
                        title: marker.name
                    });
                    const infowindow = new google.maps.InfoWindow({
                        content: markerContent
                    });

                    visibleMarker.addListener('click', function() {
                        infowindow.open(map, visibleMarker);
                    });

                    bounds.extend(visibleMarkerLatLng);
                    markersOnMap.push(visibleMarker);
                });

                map.fitBounds(bounds);
            }
        };
    });

}