import fullpage from 'fullpage.js';
import { scrollToY } from './scrollToY';

const moduleName = 'page';

export function page() {

    Array.from(document.querySelectorAll('.js-page')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const BREAKPOINT = 1240;
        const page = self;
        const pageSlider = page.querySelector('.js-page-slider');
        const pageSlides = page.querySelectorAll('.js-page-slide');
        const pageNextButton = page.querySelector('.js-page-button-next');
        const pageOrderButton = page.querySelector('.js-page-button-order');
        const pageAnchors = page.querySelectorAll('.js-page-anchor');
        let windowWidth = document.body.clientWidth;
        let event = document.createEvent('Event');

        event.initEvent('fullpageScreenChange', true, true);

        initFullpage();

        pageNextButton.addEventListener('click', () => {
            $.fn.fullpage.moveSectionDown();
        });

        pageOrderButton.addEventListener('click', () => {
            const orderAreaEntry = document.querySelector('.js-order-area-entry');

            if (orderAreaEntry.value > 0) {
                if (windowWidth >= BREAKPOINT) {
                    $.fn.fullpage.moveSectionDown();
                } else {
                    let target = document.querySelector(pageOrderButton.dataset.target);
                    let y = target.getBoundingClientRect().top + (window.scrollY || document.body.scrollTop);

                    scrollToY(y);
                }
            } else {
                orderAreaEntry.value = '';
                orderAreaEntry.classList.add('_error');
                orderAreaEntry.focus();
            }
        });

        Array.from(pageAnchors).forEach((pageAnchor, index) => {
            pageAnchor.addEventListener('click', (event) => {
                event.preventDefault();
                popup().close();

                if (windowWidth >= BREAKPOINT) {
                    $.fn.fullpage.moveTo(index + 1);
                } else {
                    let target = document.querySelector(pageAnchor.getAttribute('href'));
                    let y = target.getBoundingClientRect().top + (window.scrollY || document.body.scrollTop);

                    scrollToY(y);
                }
            });
        });

        if (windowWidth < BREAKPOINT) $.fn.fullpage.destroy('all');

        window.addEventListener('resize', () => {
            let newWindowWidth = document.body.clientWidth;

            if (windowWidth >= BREAKPOINT && newWindowWidth < BREAKPOINT) {
                $.fn.fullpage.destroy('all');
            } else if (windowWidth < BREAKPOINT && newWindowWidth >= BREAKPOINT) {
                initFullpage();
            }

            windowWidth = newWindowWidth;
        });



        function initFullpage() {
            $(pageSlider).fullpage({
                sectionSelector: '.js-page-slide',
                scrollingSpeed: 700,
                navigation: true,
                onLeave: (origin, destination, direction) => {
                    setPageClasses(destination);
                    setTimeout(() => {
                        document.dispatchEvent(event);
                    }, 700);
                },
                afterLoad: (origin, destination, direction) => {
                    setPageClasses(destination);
                },
                normalScrollElements: '.popup'
            });
        }

        function setPageClasses(destination) {
            const activeIndex = destination - 1;
            const activeSlide = pageSlides[activeIndex];
            const isDark = !!activeSlide.dataset.dark;

            if (isDark) {
                document.body.classList.add('dark');
            } else {
                document.body.classList.remove('dark');
            }
        }
    });

}