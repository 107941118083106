import Swiper from 'swiper';

const moduleName = 'about';

export function about() {

    Array.from(document.querySelectorAll('.js-about')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const holder = self;
        const slider = holder.querySelector('.js-about-slider');
        const bullets = holder.querySelectorAll('.js-about-bullet');
        const prevButton = holder.querySelector('.js-about-button-prev');
        const nextButton = holder.querySelector('.js-about-button-next');
        const fractions = holder.querySelector('.js-about-fractions');
        const swiper = new Swiper(slider, {
            init: false,
            slideActiveClass: '_active',
            speed: 500,
            loop: true,
            navigation: {
                prevEl: prevButton,
                nextEl: nextButton,
                disabledClass: '_disabled'
            },
            on: {
                init: updateFractions,
                slideChange: () => {
                    activateBullet(swiper.realIndex);
                    updateFractions();
                }
            }
        });
        let activeIndex = null;

        activateBullet();
        swiper.init();

        Array.from(bullets).forEach((bullet, index) => {
            bullet.addEventListener('click', () => {
                swiper.slideTo(index + 1);
                activateBullet(index);
            });
        });



        function activateBullet(index) {
            if (activeIndex === null) {
                bullets[0].classList.add('_active');
                activeIndex = 0;
            } else {
                bullets[activeIndex].classList.remove('_active');
                bullets[index].classList.add('_active');
                activeIndex = index;
            }
        }

        function updateFractions() {
            fractions.innerHTML = `<span>${swiper.realIndex + 1}</span> / ${swiper.slides.length - 2}`;
        }
    });

}