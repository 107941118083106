import Swiper from 'swiper';
import inView from 'in-view';

const moduleName = 'use';

export function use() {

    Array.from(document.querySelectorAll('.js-use')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const BREAKPOINT = 768;
        const holder = self;
        const bgSlider = holder.querySelector('.js-use-bg-slider');
        const bgSliderWrapper = holder.querySelector('.js-use-bg-slider-wrapper');
        const bgSliderSlides = holder.querySelectorAll('.js-use-bg-slide');
        const slider = holder.querySelector('.js-use-slider');
        const sliderWrapper = holder.querySelector('.js-use-slider-wrapper');
        const sliderSlides = holder.querySelectorAll('.js-use-slide');
        const prevButton = holder.querySelector('.js-use-button-prev');
        const nextButton = holder.querySelector('.js-use-button-next');
        const bullets = holder.querySelectorAll('.js-use-bullet');
        let bgSwiper;
        let swiper;
        let activeIndex = null;
        let windowWidth = document.body.clientWidth;

        if (windowWidth >= BREAKPOINT) initSwipers();

        window.addEventListener('resize', () => {
            let newWindowWidth = document.body.clientWidth;

            if (newWindowWidth >= BREAKPOINT && windowWidth < BREAKPOINT) {
                initSwipers();
            } else if (newWindowWidth < BREAKPOINT && windowWidth >= BREAKPOINT) {
                destroySwipers();
            }

            windowWidth = newWindowWidth;
        });


        function initSwipers() {
            bgSlider.classList.add('swiper-container');
            bgSliderWrapper.classList.add('swiper-wrapper');
            Array.from(bgSliderSlides).forEach((bgSliderSlide) => {
                bgSliderSlide.classList.add('swiper-slide');
            });
            slider.classList.add('swiper-container');
            sliderWrapper.classList.add('swiper-wrapper');
            Array.from(sliderSlides).forEach((sliderSlide) => {
                sliderSlide.classList.add('swiper-slide');
            });

            bgSwiper = new Swiper(bgSlider, {
                init: false,
                effect: 'fade',
                speed: 500,
                loop: true,
                autoplay: {
                    delay: 15000,
                    disableOnInteraction: false
                }
            });

            swiper = new Swiper(slider, {
                init: false,
                effect: 'fade',
                slideActiveClass: '_active',
                speed: 500,
                loop: true,
                autoplay: {
                    delay: 15000,
                    disableOnInteraction: false
                },
                navigation: {
                    prevEl: prevButton,
                    nextEl: nextButton,
                    disabledClass: '_disabled'
                },
                on: {
                    slideChangeTransitionStart: () => {
                        bgSwiper.slideTo(swiper.activeIndex);
                        activateBullet(swiper.realIndex);
                    }
                }
            });

            activateBullet();
            bgSwiper.init();
            bgSwiper.autoplay.stop();
            swiper.init();
            swiper.autoplay.stop();
            window.addEventListener('scroll', checkHolderVisibility);
            document.addEventListener('fullpageScreenChange', checkHolderVisibility);

            Array.from(bullets).forEach((bullet, index) => {
                bullet.addEventListener('click', () => {
                    swiper.slideTo(index + 1);
                    bgSwiper.slideTo(index + 1);
                    activateBullet(index);
                });
            });
        }

        function destroySwipers() {
            bgSlider.classList.remove('swiper-container');
            bgSliderWrapper.classList.remove('swiper-wrapper');
            Array.from(bgSliderSlides).forEach((bgSliderSlide) => {
                bgSliderSlide.classList.remove('swiper-slide');
            });
            slider.classList.remove('swiper-container');
            sliderWrapper.classList.remove('swiper-wrapper');
            Array.from(sliderSlides).forEach((sliderSlide) => {
                sliderSlide.classList.remove('swiper-slide');
            });

            swiper.destroy();
            bgSwiper.destroy(true, false);
            activeIndex = null;
            holder.querySelector('.js-use-bullet._active').classList.remove('_active');
        }

        function activateBullet(index) {
            if (activeIndex === null) {
                bullets[0].classList.add('_active');
                activeIndex = 0;
            } else {
                bullets[activeIndex].classList.remove('_active');
                bullets[index].classList.add('_active');
                activeIndex = index;
            }
        }

        function checkHolderVisibility() {
            if (inView.is(holder)) startAutoplay();
        }

        function startAutoplay() {
            if (!swiper.autoplay.running) {
                swiper.autoplay.start();
                window.removeEventListener('scroll', checkHolderVisibility);
                document.removeEventListener('fullpageScreenChange', checkHolderVisibility);
            }
            if (!bgSwiper.autoplay.running) {
                bgSwiper.autoplay.start();
                window.removeEventListener('scroll', checkHolderVisibility);
                document.removeEventListener('fullpageScreenChange', checkHolderVisibility);
            }
        }
    });

}