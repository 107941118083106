const moduleName = 'ytVideo';

export function ytVideo() {

    if (document.body.clientWidth > 1024) {
        const ytScriptUrl = 'https://www.youtube.com/iframe_api';
        const scriptElement = document.createElement('script');
        const firstScriptElement = document.getElementsByTagName("script")[0];

        scriptElement.src = ytScriptUrl;
        scriptElement.async = true;
        firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
    }

    Array.from(document.querySelectorAll('.js-yt-video')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const holder = self;

        if (document.body.clientWidth <= 1024) {
            holder.classList.add('_novideo');
            return;
        }

        const player = holder.querySelector('.js-yt-video-player');
        const id = player.getAttribute('data-id');

        setVideoSize();
        window.addEventListener('resize', setVideoSize);

        window.onYouTubeIframeAPIReady = () => {
            const ytPlayer = new YT.Player(player, {
                playerVars: {
                    'autoplay': 0,
                    'modestbranding': 1,
                    'rel': 0,
                    'mute': 1,
                    'loop': 1,
                    'showinfo': 0,
                    'controls': 0
                },
                videoId: id,
                events: {
                    onReady: () => {
                        ytPlayer.playVideo();
                    },
                    onStateChange: (event) => {
                        if (event.data === YT.PlayerState.ENDED) {
                            ytPlayer.playVideo();
                        }
                    }
                }
            });
        };



        function setVideoSize() {
            const windowWidth = +document.documentElement.clientWidth;
            const windowHeight = +document.documentElement.clientHeight;

            if (windowWidth / windowHeight >= 16 / 9) {
                player.style.width = '100%';
                player.style.height = (windowWidth * 9 / 16) + 'px';
            } else {
                player.style.height = '100%';
                player.style.width = (windowHeight * 16 / 9) + 'px';
            }
        }
    });

}