import Swiper from 'swiper';
import inView from 'in-view';

const moduleName = 'benefits';

export function benefits() {

    Array.from(document.querySelectorAll('.js-benefits')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const holder = self;
        const slider = holder.querySelector('.js-benefits-slider');
        const pagination = holder.querySelectorAll('.js-benefits-pagination');
        const prevButton = holder.querySelectorAll('.js-benefits-button-prev');
        const nextButton = holder.querySelectorAll('.js-benefits-button-next');
        const fractions = holder.querySelector('.js-benefits-fractions');
        const swiper = new Swiper(slider, {
            init: false,
            loop: true,
            speed: 500,
            autoplay: {
                delay: 15000,
                disableOnInteraction: false
            },
            simulateTouch: false,
            navigation: {
                prevEl: prevButton,
                nextEl: nextButton,
                disabledClass: '_disabled'
            },
            pagination: {
                el: pagination,
                clickable: true,
                bulletClass: 'benefits__nav-item',
                bulletActiveClass: '_active',
                renderBullet: (index, className) => {
                    const bulletText = swiper.slides[index + 1].dataset.bulletText;

                    return '<div class="' + className + '">' + bulletText + '</div>';
                }
            },
            on: {
                init: updateFractions,
                slideChange: updateFractions
            }
        });

        swiper.init();
        swiper.autoplay.stop();
        window.addEventListener('scroll', checkHolderVisibility);
        document.addEventListener('fullpageScreenChange', checkHolderVisibility);



        function checkHolderVisibility() {
            if (inView.is(holder)) startAutoplay();
        }

        function startAutoplay() {
            if (!swiper.autoplay.running) {
                swiper.autoplay.start();
                holder.classList.add('_autoplayed');
                window.removeEventListener('scroll', checkHolderVisibility);
                document.removeEventListener('fullpageScreenChange', checkHolderVisibility);
            }
        }

        function updateFractions() {
            fractions.innerHTML = `<span>${swiper.realIndex + 1}</span> / ${swiper.slides.length - 2}`;
        }
    });

}