const moduleName = 'customFile';

export function customFile() {

    Array.from(document.querySelectorAll('.js-custom-file')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const holder = self;
        const input = holder.querySelector('.js-custom-file-input');
        const name = holder.querySelector('.js-custom-file-name');

        input.addEventListener('change', updateFileName);



        function updateFileName() {
            const file = input.files[0];
            const fileName = file ? file.name : undefined;

            if (fileName) {
                name.innerHTML = fileName;
                holder.classList.add('_active');
            } else {
                name.innerHTML = placeholder;
                holder.classList.remove('_active');
            }
        }
    });

}