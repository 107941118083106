export function overlay() {

    const html = document.documentElement;
    const body = document.body;
    let overlay = document.querySelector('.overlay');

    function showOverlay() {
        addOverlay();

        if (!overlay.classList.contains('_visible')) {
            setTimeout(() => {
                overlay.classList.add('_visible');
                fixViewport();
            }, 0);
        }
    }

    function hideOverlay() {
        overlay.classList.remove('_visible');
        unfixViewport();
    }

    function addOverlay() {
        if (overlay) return;

        overlay = document.createElement('div');
        overlay.classList.add('overlay');
        document.body.appendChild(overlay);
    }

    function fixViewport() {
        const scrollY = window.scrollY || document.documentElement.scrollTop;

        if (window.innerWidth > document.documentElement.clientWidth) html.classList.add('has-scrollbar');

        body.style.marginTop = `-${scrollY}px`;
        html.classList.add('fixed');
    }

    function unfixViewport() {
        const newScrollTop = -body.style.marginTop.slice(0, -2);

        html.classList.remove('fixed');
        html.classList.remove('has-scrollbar');
        body.style.marginTop = '';
        window.scrollTo(0, newScrollTop);
    }

    return {
        show: showOverlay,
        hide: hideOverlay
    };

}