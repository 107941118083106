import Drift from 'drift-zoom';

const moduleName = 'imageZoom';

export function imageZoom() {

    Array.from(document.querySelectorAll('.js-image-zoom')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        if (document.body.classList.contains('mobile')) return;

        const holder = self;
        const image = holder.querySelector('.js-image-zoom-hero');
        const drift = new Drift(image, {
            paneContainer: document.getElementById('image-zoom'),
            hoverBoundingBox: true,
            inlineOffsetY: -75,
            inlinePane: 1023
        });
    });

}