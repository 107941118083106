import IMask from 'imask';

const moduleName = 'inputMask';

export function inputMask() {

    Array.from(document.querySelectorAll('input[type="tel"]')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const input = self;
        const inputMask = IMask(input, {
            mask: '+{375} (00) 000-00-00'
        });
    });

    Array.from(document.querySelectorAll('.js-input-without-numbers')).forEach((self) => {
        if (self.dataset[moduleName + 'Init'] === 'true') return;
        self.dataset[moduleName + 'Init'] = true;

        const input = self;
        const inputMask = IMask(input, {
            mask: (value) => {
                return /\d/.test(value) ? false : true;
            }
        });
    });

}